import { ContextMenu } from "@components/library/ContextMenu";
import IconButton from "@components/library/IconButton";
import Tooltip from "@components/library/Tooltip";
import { CogIcon, EyeIcon, EyeOffIcon, XIcon } from "@heroicons/react/outline";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { AgGridReact } from "ag-grid-react";
import { useTableColumnState } from "context/TableColumnStateContext";
import { MutableRefObject, useState } from "react";

interface Props<T> {
  gridRef: MutableRefObject<AgGridReact<T> | null>;
}

export const CHECKBOX_COL_ID = "checkbox";

/** Controls what columns are hidden */
export const TableSettingsMenu = <T extends unknown>({ gridRef }: Props<T>) => {
  // We do not actually need to control dropdown open state here.
  // This is just to rerender the content with a loaded gridRef (so columns can be fetched).
  // Otherwise, the component will only render once, without any columns.
  const [open, setOpen] = useState(false);
  // TODO: above might no longer be true, as this component no longer directly messes with the gridRef.

  const {
    reset: resetColumns,
    onGridReadyPreserveColumnState,
    getColumnDetails,
    setColumnState,
    setColumnVisibility,
  } = useTableColumnState();

  const columnDetails = getColumnDetails();
  // Each column needs an ID and a headerName to be displayed in the dropdown.
  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <Tooltip side="top" content="Table settings">
        <DropdownMenu.Trigger asChild>
          <IconButton Icon={CogIcon} shade={"gray"} size={32} active={open} onClick={() => setOpen((open) => !open)} />
        </DropdownMenu.Trigger>
      </Tooltip>
      <DropdownMenu.Portal>
        <ContextMenu.Items isDropdownMenu align="end" sideOffset={4} className="max-h-[280px] overflow-y-auto">
          {columnDetails?.map((columnDetail) => {
            const { colId, headerName, visible } = columnDetail;
            if (!colId || !headerName) return null;
            return (
              <ContextMenu.Item
                key={colId}
                withinDropdownMenu
                onClick={(event) => {
                  event.preventDefault();
                  setColumnVisibility(colId, !visible);
                }}
                IconRight={!visible ? EyeOffIcon : EyeIcon}
                className={!visible ? "opacity-40 hover:opacity-100" : ""}
              >
                <span className="mr-24">{headerName}</span>
              </ContextMenu.Item>
            );
          })}
          <hr />
          <ContextMenu.Item
            withinDropdownMenu
            onClick={(event) => {
              event.preventDefault();
              resetColumns();
            }}
            IconRight={XIcon}
          >
            Reset
          </ContextMenu.Item>
        </ContextMenu.Items>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
