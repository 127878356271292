import init, { Workspace } from "@astral-sh/ruff-wasm-web";

let workspace: Workspace | undefined = undefined;

if (typeof window !== "undefined") {
  init().then(() => {
    workspace = new Workspace({
      "line-length": 88,
      "indent-width": 4,
      "format": {
        "indent-style": "space",
        "quote-style": "double",
      },
      "lint": {
        select: ["E4", "E7", "E9", "F"],
      },
    });
  });
}

/** Format Python with ruff.
 *
 * Only available in the browser, not in the server.
 * Usage in the server may be possible if we conditionally use the `@astral-sh/ruff-wasm-nodejs` package.
 */
export const formatPython = (code: string): string => {
  if (!workspace) {
    return code;
  }
  return workspace?.format(code);
};
