import { classNames } from "@/lib/utils";
import { SnippetData, SnippetLanguage, SnippetRequest } from "@/pages/api/snippets";
import { getSnippet } from "@/services/snippets";
import { TableTabSelect } from "@components/library/Tabs/TableTabs";
import { Icon as PhosphorIcon } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import useLocalStorageState from "use-local-storage-state";
import { CodePreviewWithCopyButton } from "./Dashboard/CodeSnippet";
import { Skeleton } from "antd";

interface EmptyStateProps {
  header: React.ReactNode;
  body: React.ReactNode;
  Icon?: PhosphorIcon | React.ComponentType<React.SVGProps<SVGSVGElement>>;
  /* Buttons to be displayed at the bottom of the empty state. Should be of size 24-32. (Designs have size 24, but our size 24 buttons are currently too thin. So I'm going for 28.) */
  buttons?: React.ReactNode;
  border?: boolean;
  otherColumns?: React.ReactNode | React.ReactNode[];
  fillSpace?: boolean;
  className?: string;
}

export const EmptyState = ({
  Icon,
  header,
  body,
  buttons,
  border = true,
  otherColumns,
  fillSpace,
  className,
}: EmptyStateProps) => {
  return (
    <div className={classNames("flex h-full w-full grow flex-col items-center justify-center py-40")}>
      <div
        className={classNames(
          "flex max-w-full justify-start overflow-auto rounded-xl bg-background-base-1",
          border && "border border-stroke-base-3",
          fillSpace ? "h-full w-full" : "h-[330px]",
          className,
        )}
      >
        <Column>
          <div className="flex flex-col gap-[13px]">
            {Icon ? <Icon className="h-24 w-24" /> : null}
            <div className="flex items-center gap-x-16">
              <div className="text-16-24 font-bold text-text-base-1">{header}</div>
            </div>
            <div className="text-14-20 leading-[20px] text-text-base-2 [text-wrap:balance]">{body}</div>
          </div>
          {buttons && <div className="flex items-start gap-12">{buttons}</div>}
        </Column>
        {otherColumns &&
          (Array.isArray(otherColumns) ? (
            otherColumns.map((column, index) => <Column key={index}>{column}</Column>)
          ) : (
            <Column>{otherColumns}</Column>
          ))}
      </div>
    </div>
  );
};

export const EmptyStateSmall = ({
  Icon,
  header,
  body,
  buttons,
  border = true,
  fillSpace,
  className,
}: EmptyStateProps) => {
  return (
    <div className={classNames("flex h-full w-full grow flex-col items-center justify-center py-40", className)}>
      <div
        className={classNames(
          "flex max-w-full items-start justify-start gap-20  overflow-auto rounded-xl",
          border && "border border-stroke-base-3",
        )}
      >
        <div className="flex items-center justify-center ">{Icon ? <Icon className="h-24 w-24" /> : null}</div>
        <div className="flex flex-col gap-[13px]">
          <div className="flex items-center gap-x-16">
            <div className="text-16-24 font-bold text-text-base-1">{header}</div>
          </div>
          <div className="text-14-20 leading-[20px] text-text-base-2 [text-wrap:balance]">{body}</div>
        </div>
        {buttons && <div className="flex items-start gap-12">{buttons}</div>}
      </div>
    </div>
  );
};

const Column = ({ children }: { children: React.ReactNode }) => (
  <div className="flex h-full w-[360px] flex-1 flex-col justify-center gap-24 border-r p-40 last:border-none">
    {children}
  </div>
);
const DEFAULT_LANGUAGE = "python";

/**
 * Column showing generated code snippets for the empty state
 */
export const EmptyStateGeneratedSnippet = ({ snippetRequest }: { snippetRequest: SnippetRequest }) => {
  const [snippetLanguage, setSnippetLanguage] = useLocalStorageState<SnippetLanguage>("snippet-language", {
    defaultValue: DEFAULT_LANGUAGE,
  });
  const [snippetData, setSnippetData] = useState<SnippetData[] | undefined>();

  useEffect(() => {
    const fn = async () => {
      const data = await getSnippet(snippetRequest);
      setSnippetData(data);
    };
    fn();
  }, [snippetRequest]);

  const snippets = snippetData?.map((d) => ({
    type: d.type,
    snippet: extractSnippet(d),
  }));

  const snippet = snippets?.find((d) => d.type === snippetLanguage);

  return (
    <div className="flex min-h-0 flex-col gap-12">
      <div>
        <TableTabSelect<SnippetLanguage>
          size={24}
          value={snippetLanguage ?? "python"}
          setValue={setSnippetLanguage}
          options={[
            { label: "Python", value: "python" },
            { label: "TypeScript", value: "typescript" },
          ]}
        />
      </div>

      <div className="relative flex min-h-0 max-w-480 flex-col gap-24">
        {snippet ? (
          <CodePreviewWithCopyButton code={snippet.snippet} className="!text-11-16" />
        ) : (
          <Skeleton className="mt-32" />
        )}
      </div>
    </div>
  );
};

/**
 * Extract the appropriate code snippet to display
 *
 * Default to the sync client snippet for Python
 */
const extractSnippet = (snippetData: SnippetData): string => {
  switch (snippetData.type) {
    case "python":
      return snippetData.sync_client;
    case "typescript":
      return snippetData.client;
  }
};

/**
 * Column showing hardcoded code snippets for the empty state
 */
export const EmptyStateHardcodedSnippet = ({ snippet }: { snippet: string }) => {
  return (
    <div className="flex min-h-0 flex-col gap-12">
      <div className="relative flex min-h-0 max-w-480 flex-col gap-24">
        {snippet ? (
          <CodePreviewWithCopyButton code={snippet} className="!text-11-16" />
        ) : (
          <Skeleton className="mt-32" />
        )}
      </div>
    </div>
  );
};
