import { ConditionalWrapper } from "@/lib/utils";
import { ContextMenu } from "@components/library/ContextMenu";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

export type Action<T> = MenuItem<T> | Divider;

export interface MenuItem<T> {
  menuItemProps: (props: { data: T }) => React.ComponentPropsWithRef<typeof ContextMenu.Item> | null;
  Wrapper?: (props: React.PropsWithChildren<{ data: T }>) => JSX.Element;
  children?: React.ReactNode; // Elements to render outside of the dropdown menu
}
export interface Divider {
  type: "divider";
}

type ActionsMenuProps<T> = {
  trigger: JSX.Element;
  actions: Action<T>[];
  data?: T;
};

const ActionsMenu = <T,>({ trigger, actions, data }: ActionsMenuProps<T>) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        onClick={(event) => {
          event.stopPropagation();
        }}
        asChild
      >
        {trigger}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <ContextMenu.Items
          isDropdownMenu
          className="z-[1010] max-h-320 w-max max-w-[200px] overflow-y-auto shadow-b"
          align="end"
          sideOffset={4}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {data !== undefined &&
            actions.map((action, i) => {
              if (isDivider(action)) {
                return <hr key={i} className="h-[1.5px] bg-oldgray-400" />;
              } else {
                const props = action.menuItemProps({ data });
                if (props === null) return null;

                const Wrapper = action.Wrapper;
                if (Wrapper !== undefined) {
                  return (
                    <ConditionalWrapper
                      key={i}
                      wrapper={(children) => <Wrapper data={data}>{children}</Wrapper>}
                      condition={Wrapper !== undefined}
                    >
                      <ContextMenu.Item withinDropdownMenu {...props} />
                    </ConditionalWrapper>
                  );
                } else {
                  return <ContextMenu.Item key={i} withinDropdownMenu {...props} />;
                }
              }
            })}
        </ContextMenu.Items>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export function isDivider<T>(action: MenuItem<T> | Divider): action is Divider {
  return (action as Divider).type === "divider";
}

export default ActionsMenu;
