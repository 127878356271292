import { formatPython } from "@/lib/code-utils";
import type { SnippetData, SnippetRequest } from "@/pages/api/snippets";
import { Prompt } from "@/types/app/prompt";
import { extractInputsFromPromptVersion } from "@components/playground/utils";
import axios from "axios";

export const getSnippet = async (request: SnippetRequest): Promise<SnippetData[]> => {
  const response = await axios.post<SnippetData[]>(`/api/snippets`, request);

  return response.data.map((d) => {
    switch (d.type) {
      case "python":
        return {
          ...d,
          async_client: formatPython(d.async_client),
          sync_client: formatPython(d.sync_client),
        };
      case "typescript":
        return d;
    }
  });
};

export const getPromptLogSnippetPayload = (prompt: Prompt) => ({
  path: prompt.path,
  inputs: Object.fromEntries(extractInputsFromPromptVersion(prompt).map((input) => [input, "value"])),
  messages: [{ role: "user", content: "Tell me about Humanloop" }],
  output_message: { role: "system", content: "Humanloop is the LLM evals platform for enterprises." },
});
