// Rounded tabs that go above a table
// https://www.figma.com/file/Ya7mCOIZJp1UdBCH3VxnEL/Components-v2?type=design&node-id=2180-6762&mode=design&t=PvJOHp50Wc4Kenli-11

import { classNames } from "@/lib/utils";
import { Tab as HeadlessUiTab, RadioGroup } from "@headlessui/react";
import { ComponentProps, forwardRef, Ref } from "react";
import { UnstyledButtonBase, UnstyledButtonBaseProps } from "../Button";

interface TableTabProps extends UnstyledButtonBaseProps {
  selected?: boolean;
  Icon?: (props: React.ComponentPropsWithoutRef<"svg">) => JSX.Element;
  size?: 24 | 28 | 32;
  className?: string;
  children: React.ReactNode;
}

export const TableTab = ({ selected, Icon, size = 32, className, children, disabled, ...props }: TableTabProps) => {
  return (
    <UnstyledButtonBase
      disabled={disabled}
      className={classNames(
        "flex select-none items-center gap-8 rounded-ms text-12-12 font-medium outline-none focus:shadow-focus-ring",
        selected ? "bg-background-base-3" : "hover:bg-background-base-3 focus:bg-background-base-1",
        selected ? "text-text-base-1" : "text-text-base-3 hover:text-text-base-1",
        disabled && "pointer-events-none opacity-50",
        !disabled && selected && "hover:bg-background-neutral-4",
        !disabled && !selected && "hover:text-text-base-1",
        size === 32 && "h-32 px-12",
        size === 28 && "h-28 px-12",
        size === 24 && "h-24 px-8",
        className,
      )}
      {...props}
    >
      {Icon && (
        <Icon
          className={classNames(
            "-my-2 -ml-2 h-16 w-16",
            selected ? "focus:text-icons-base-1 text-icon-base-2 hover:text-icon-base-1" : "text-icon-base-3",
          )}
        />
      )}
      {children}
    </UnstyledButtonBase>
  );
};

interface Option<T = string> {
  label: string;
  labelNode?: (props: { selected: boolean }) => React.ReactNode;
  value: T;
}

/** Table Tabs without expecting that they're actually "tabs". They're laid out select items */
export const TableTabSelect = <T,>({
  options,
  value,
  setValue,
  disabled,
  size,
  className,
}: {
  options: Option<T>[];
  value: T;
  setValue: (value: T) => void;
  disabled?: boolean;
  size?: 24 | 28 | 32;
  className?: string;
}) => {
  return (
    <RadioGroup
      className={classNames("flex flex-wrap gap-4", className)}
      value={value}
      onChange={setValue}
      disabled={disabled}
    >
      {options.map((option) => (
        <RadioGroup.Option key={option.label} value={option.value}>
          {({ checked }) => (
            <TableTab selected={checked} disabled={disabled} size={size}>
              {option.labelNode ? option.labelNode({ selected: checked }) : option.label}
            </TableTab>
          )}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};

const List = ({ className, ...props }: ComponentProps<typeof HeadlessUiTab.List>, ref?: Ref<HTMLElement>) => (
  <HeadlessUiTab.List ref={ref} {...props} className={classNames("flex gap-4 ", className)} />
);

const Panels = ({ className, ...props }: ComponentProps<typeof HeadlessUiTab.Panels>, ref?: Ref<HTMLElement>) => (
  <HeadlessUiTab.Panels ref={ref} {...props} className={classNames(className)} />
);

const Panel = ({ className, ...props }: ComponentProps<typeof HeadlessUiTab.Panel>, ref?: Ref<HTMLElement>) => (
  <HeadlessUiTab.Panel ref={ref} {...props} className={classNames("focus:outline-none", className)} />
);

const Tab = (
  props: ComponentProps<typeof HeadlessUiTab> & TableTabProps & { ref?: Ref<HTMLElement> },
  ref?: Ref<HTMLElement>,
) => <HeadlessUiTab ref={ref} {...props} as={TableTab} />;

const Tabs = {
  Group: HeadlessUiTab.Group,
  List: forwardRef(List),
  Panels: forwardRef(Panels),
  Panel: forwardRef(Panel),
  Tab: forwardRef(Tab),
};

export default Tabs;
