import { ModelUpdatedEvent, RowClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { FlowLog, Log } from "@/types/app/log";
import { getLog } from "@/services/logs.service";
import { EvaluationLog } from "@/services/evaluationReports.service";

interface Options<IdDataKey extends string = "id"> {
  useIdQueryParam?: boolean;
  idQueryParam?: string; // The key to use in the URL query string
  idDataKey?: IdDataKey; // The key to use in the data to identify the row
  suppressHotkeys?: boolean;
}

type WithIdDataKey<IdDataKey extends string, T> = T & Record<IdDataKey, string>;

/**
 *  Custom hook to handle selecting and navigating between rows in a table
 *
 *  Here's what this hook tries to do:
 *
 * We track the table data (the row of data) that is selected with state (selectedTableData)
 * If the ID is in the URL, we will try to select that row in the table.
 * If the the selected row in the table changes, we will update the URL, and the state.
 *
 * If the ID in the URL is not available in the table, we try to fetch it from the backend.
 * This currently needs to happen outside of this hook, as we need to fetch the data.
 * This is something that maybe should be handled directly in the table's data fetching, going forward.
 *
 * @param gridRef - The ref of the ag-grid table
 * @param options - Options for the hook
 * @param options.useIdQueryParam - If true, the ID of the row will be put in the URL query string
 * @param options.idQueryParam - The key to use in the URL query string
 * @param options.idDataKey - The key to use in the data to identify the row
 * @param options.suppressHotkeys - If true, the hotkeys will be disabled
 */
function useTableSelectedRow<
  IdDataKey extends string = "id",
  T extends WithIdDataKey<IdDataKey, unknown> = WithIdDataKey<IdDataKey, unknown>,
>(gridRef: React.MutableRefObject<AgGridReact<T> | null>, options?: Options<IdDataKey>) {
  const {
    useIdQueryParam = false,
    idQueryParam = "id",
    idDataKey = "id" as IdDataKey,
    suppressHotkeys = false,
  } = options || {};

  const router = useRouter();

  // Here's what this hook tries to do:
  //
  // "Abstract away the logic of selecting a row in the table, and navigating next/previous between rows."
  //
  // We track the table data (the row of data) that is selected with state (selectedTableData)
  // If the ID is in the URL, we will try to select that row in the table.
  // If the the selected row in the table changes, we will update the URL, and the state.
  //
  // If the ID in the URL is not available in the table, we try to fetch it from the backend.
  // This currently needs to happen outside of this hook, as we need to fetch the data.
  // This is something that maybe should be handled directly in the table's data fetching, going forward.
  const setQueryParamId = useMemo(() => {
    return (id: string | null) => {
      if (useIdQueryParam) {
        // First check if a replace is needed.
        const currentIdValue = router.query[idQueryParam];
        const currentId = Array.isArray(currentIdValue) ? currentIdValue[0] : currentIdValue;
        if ((id === null && currentId === undefined) || currentId === id) {
          return;
        }

        const query = id ? { ...router.query, [idQueryParam]: id } : _.omit(router.query, idQueryParam);
        router.replace(
          {
            pathname: router.pathname,
            query,
          },
          undefined,
          { shallow: true },
        );
      }
    };
  }, [idQueryParam, router, useIdQueryParam]);

  // Suspect we can have a cleaner API and less state, by only tracking the selected row node
  // rather than it's contents (rowNode.data is the same as selectedTableData). That way we
  // don't need to worry about keeping the two in sync and can edit the table's values directly.
  // Edit: having kinda gone down this path, I don't think it's a great idea yet. The table
  // does not contain all the data, as you for example can come to the datapoints table for ID 123
  // which doesn't exist on the first batch of data.
  const [selectedTableData, _setSelectedTableData] = useState<T | null>(null);
  const [detailViewOpen, setDetailViewOpenRaw] = useState(false);
  const setDetailViewOpen = useCallback(
    (open: boolean) => {
      setDetailViewOpenRaw(open);
      if (!open) {
        setQueryParamId(null);
      }
      setDetailViewOpenRaw(open);
    },
    [setQueryParamId],
  );

  /*
    Before passing a FlowLog to the detail view, fetch the full trace if data
    is a FlowLog or an EvaluationLog that contains a FlowLog.
   */
  const fetchTraceIfFlowLog = async (data: T | undefined): Promise<T | undefined> => {
    // TODO: Refactor to use /logs when we unify log GETs

    let log = { ...data };
    if (!data) return undefined;

    if ("log" in data) {
      // EvaluationLog, Log nested inside could be a FlowLog
      log = (data as unknown as EvaluationLog).log;
    } else {
      log = data as unknown as Log;
    }

    if ("flow" in log) {
      // Populate the trace children only if the row is selected
      // useLogs will hit /logs which onl returns head of traces
      // getLog will hit /logs/{id} which returns the full trace
      const fullTrace = await getLog((data as unknown as FlowLog).id);
      if ("log" in (data as unknown as FlowLog)) {
        (data as unknown as EvaluationLog).log = fullTrace;
      } else {
        (data as unknown as FlowLog) = log as unknown as FlowLog;
      }
      return data;
    }

    return data;
  };

  // Fetch ful trace when selecting a Flow Log
  const setSelectedTableData: React.Dispatch<T | null> = useCallback((data: T | null) => {
    if (data && "flow" in data) {
      // Populate the trace children only if the row is selected
      // useLogs will hit /logs which onl returns head of traces
      // getLog will hit /logs/{id} which returns the full trace
      getLog((data as unknown as FlowLog).id).then((log) => {
        _setSelectedTableData(log as unknown as T);
      });
    } else {
      _setSelectedTableData(data || null);
    }
  }, []);

  /*
   * Handle 'next' action to move to the next row in the table.
   * Will select the first row on the page if no row is selected.
   */
  const onNext: (() => void) | undefined = useMemo(() => {
    if (!gridRef?.current?.api) return;

    const api = gridRef.current.api;
    const selectedRows = api.getSelectedNodes();
    if (selectedRows.length === 0) {
      return;
    }
    // rowIndex is null if the row is no longer displayed (ie. filtered out).
    // The behavior where 'next' just starts from the top (like it does without
    // any selection) is desired in this case.
    const selectedRowIndex = selectedRows[0].rowIndex || 0;

    const lastDisplayedRowIndex = api.getLastDisplayedRowIndex();
    if (
      selectedRowIndex === lastDisplayedRowIndex &&
      api.paginationGetCurrentPage() === api.paginationGetTotalPages() - 1
    ) {
      // On last row of last page.
      return;
    }

    return () => {
      gridRef.current?.api.forEachNode((rowNode) => {
        if (rowNode.rowIndex === selectedRowIndex) {
          rowNode.setSelected(false);
        } else if (rowNode.rowIndex === selectedRowIndex + 1) {
          if (selectedRowIndex === lastDisplayedRowIndex) {
            // On last row - go to next page.
            api.paginationGoToNextPage();
          }
          fetchTraceIfFlowLog(rowNode.data).then((log) => {
            setSelectedTableData(log || null);
          });
          rowNode.setSelected(true);
        }
      });
    };
    // Force recreation of this onNext callback when selectedTableData changes,
    // as api.getSelectedNodes() changes.
    // (selectedTableData cannot be used directly as it does not contain rowIndex.)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTableData, gridRef.current?.api]);

  /*
   * Handle 'previous' action to move to the previous row in the table.
   */
  const onPrevious: (() => void) | undefined = useMemo(() => {
    if (!gridRef?.current?.api) return;

    const api = gridRef.current.api;
    const selectedRows = api.getSelectedNodes();
    if (selectedRows.length === 0) {
      return;
    }
    const selectedRowIndex = selectedRows[0].rowIndex || 0;
    const firstDisplayedRowIndex = api.getFirstDisplayedRowIndex();
    if (selectedRowIndex === firstDisplayedRowIndex && api.paginationGetCurrentPage() === 0) {
      // On first row of first page.
      return;
    }
    return () => {
      gridRef.current?.api.forEachNode((rowNode) => {
        if (rowNode.rowIndex === selectedRowIndex) {
          rowNode.setSelected(false);
        } else if (rowNode.rowIndex === selectedRowIndex - 1) {
          if (selectedRowIndex === firstDisplayedRowIndex) {
            // On first row - got to previous page.
            api.paginationGoToPreviousPage();
          }
          fetchTraceIfFlowLog(rowNode.data).then((log) => {
            setSelectedTableData(log || null);
          });
          rowNode.setSelected(true);
        }
      });
    };
    // Force recreation of this onPrevious callback when selectedTableData changes,
    // as api.getSelectedNodes() changes.
    // (selectedTableData cannot be used directly as it does not contain rowIndex.)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTableData, gridRef.current?.api?.getSelectedNodes()]);

  const onRowClicked = useCallback(
    ({ data, node, event }: RowClickedEvent<T>) => {
      const eventTarget = event?.target as EventTarget;

      // Some event targets are not DOM elements, but they usually are: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget
      if (!(eventTarget instanceof Element)) return;

      if (clickOnAntdCheckbox(eventTarget)) {
        console.log("Ignoring table click on button/checkbox. Not selecting data...");
        return;
      }
      if (node.isSelected()) {
        fetchTraceIfFlowLog(data).then((data) => {
          setSelectedTableData(data || null);
          setDetailViewOpen(true);
        });
      }
    },
    [setDetailViewOpen],
  );

  const onModelUpdated = useCallback(
    (event: ModelUpdatedEvent<T>) => {
      // Update selectedTableData if underlying row's data has been modified
      const selectedRows = event.api.getSelectedRows();
      if (selectedRows.length > 0 && selectedTableData !== null) {
        fetchTraceIfFlowLog(selectedRows[0]).then((log) => {
          setSelectedTableData(log || null);
        });
      }
    },
    [selectedTableData],
  );

  // Add [idQueryParam]=[id] to url if useIdQueryParam is true and we have the row open.
  useEffect(() => {
    if (
      selectedTableData &&
      (selectedTableData[idDataKey] as string) !== router.query[idQueryParam] &&
      detailViewOpen
    ) {
      setQueryParamId((selectedTableData[idDataKey] as string) || null);
    }
  }, [detailViewOpen, idDataKey, idQueryParam, router, selectedTableData, setQueryParamId, useIdQueryParam]);

  useHotkeys(
    ["j", "arrowdown"],
    () => {
      if (onNext) {
        onNext();
      } else if (selectedTableData === null) {
        // Select first row on page if no row is selected.
        gridRef?.current?.api.forEachNode((rowNode) => {
          if (rowNode.rowIndex === gridRef.current?.api.getFirstDisplayedRow()) {
            fetchTraceIfFlowLog(rowNode.data).then((log) => {
              setSelectedTableData(log || null);
            });
            rowNode.setSelected(true);
          }
        });
      }
    },
    {
      // Disable if drawer open as that has keyboard shortcuts in the nav bar.
      enabled: !detailViewOpen && !suppressHotkeys,
      // This is enabled so it still works on the row's checkbox in the table.
      enableOnFormTags: ["INPUT"],
    },
    [onNext, detailViewOpen],
  );

  useHotkeys(
    ["k", "arrowup"],
    () => {
      onPrevious?.();
    },
    // Disable if drawer open as that has keyboard shortcuts in the nav bar.
    { enabled: !detailViewOpen && !suppressHotkeys, enableOnFormTags: ["INPUT"] },

    [onPrevious, detailViewOpen],
  );

  useHotkeys(
    "Escape",
    () => {
      setDetailViewOpen(false);
      setQueryParamId(null);
    },
    {
      enabled: !!(detailViewOpen || selectedTableData) && !suppressHotkeys,
      enableOnFormTags: ["INPUT"],
    },
    [detailViewOpen, selectedTableData, setDetailViewOpen],
  );
  useHotkeys(
    "Enter",
    () => {
      if (selectedTableData) {
        setDetailViewOpen(true);
      }
    },
    {
      enabled: !!selectedTableData && !suppressHotkeys,
      enableOnFormTags: ["INPUT"],
    },
    [selectedTableData, setDetailViewOpen],
  );

  return {
    selectedTableData,
    setSelectedTableData,
    onNext,
    onPrevious,
    onRowClicked,
    onModelUpdated,
    detailViewOpen,
    setDetailViewOpen,
  };
}

export default useTableSelectedRow;

export const clickOnAntdCheckbox = (eventTarget: EventTarget): boolean => {
  // Some event targets are not DOM elements, but they usually are: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget
  if (!(eventTarget instanceof Element)) return false;

  if (
    (eventTarget instanceof HTMLButtonElement && eventTarget.type === "button") ||
    (eventTarget instanceof HTMLInputElement && eventTarget.type === "checkbox") ||
    // The checkbox is expanded to be full cell width, and clicking on the cell causes two
    // click events, one is on this wrapper.
    eventTarget?.classList.contains("ant-checkbox-wrapper")
  ) {
    return true;
  }
  return false;
};
