import { ColumnState, GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useRef,
} from "react";
import { useDebouncedCallback } from "use-debounce";
import { useLocalStorage } from "usehooks-ts";

type ColumnToggleItem = {
  headerName: string;
  colId: string;
  visible: boolean;
};

interface TableColumnStateContextInterface {
  // columnState: ColumnState[];
  setColumnState: Dispatch<SetStateAction<ColumnState[]>>;
  // saveState: () => void;
  // restoreState: () => void;
  reset: () => void;
  onGridReadyPreserveColumnState: (params: GridReadyEvent) => void;
  getColumnDetails: () => ColumnToggleItem[];
  setColumnVisibility: (colId: string, isVisible: boolean) => void;
  // gridRef: MutableRefObject<AgGridReact<any> | null>;
  setGridRef: (newRef: MutableRefObject<AgGridReact<any> | null>) => void;
}

const TableColumnStateContext = createContext<TableColumnStateContextInterface | undefined>(undefined);

/** Controls column visibility, sorting, filtering, etc.
 *
 * For this to work, you need to:
 * 1. Wrap your table in a TableColumnStateContextProvider
 * 2. Pass the gridRef to the provider (at some point!)
 * 3. Pass the onGridReadyPreserveColumnState function to the grid onGridReady prop
 * 4. Add in the TableSettingsMenu component (or your own) to add in the user controls
 */
export const useTableColumnState = (gridRef?: MutableRefObject<AgGridReact<any> | null>) => {
  const context = useContext(TableColumnStateContext);
  if (context === undefined) {
    throw new Error("useTableColumnState must be used within a TableColumnStateContextProvider");
  }
  if (gridRef) {
    context.setGridRef(gridRef);
  }
  return context;
};

interface TableColumnStateContextProviderProps {
  storageKey: string;
  children: React.ReactNode;
}

export const TableColumnStateContextProvider = ({ storageKey, children }: TableColumnStateContextProviderProps) => {
  const [columnState, setColumnState] = useLocalStorage<ColumnState[]>(`${storageKey}-columnState`, []);

  // Not storing the ref in state, so we don't trigger rerenders when the ref changes
  // Create a ref object to hold the grid ref
  const gridRef = useRef<AgGridReact<any> | null>(null);

  // Define a function to update the grid ref
  const setGridRef = (newRef: MutableRefObject<AgGridReact<any> | null>) => {
    gridRef.current = newRef.current;
  };

  /** Save column state to local storage */
  const saveState = useDebouncedCallback(() => {
    if (gridRef?.current?.api) {
      const state = gridRef.current?.api.getColumnState();
      // console.log("Saving column state", state);
      setColumnState(state);
    }
  }, 200); // Debounce this callback to avoid rerendering constantly as user resizes a column.

  /** Restore state from local storage */
  const restoreState = useCallback(() => {
    if (gridRef.current?.api && !gridRef.current.api.isDestroyed() && columnState) {
      // console.log("Restoring column state", columnState);
      gridRef.current?.api.applyColumnState({ state: columnState });
    }
  }, [columnState]);

  // Attach event listeners on grid ready
  // Ag-grid should detach event listeners on unmount automatically
  const attachEventListeners = useCallback(
    (gridApi: GridApi) => {
      const events = [
        "columnMoved",
        "columnVisible",
        "columnResized",
        "columnPinned",
        "columnGroupOpened",
        "filterChanged",
        "sortChanged",
      ];
      events.forEach((event) => {
        gridApi.addEventListener(event, () => saveState());
      });
    },
    [saveState],
  );

  /**  To automatically restore and save state, you need send this to the grid onGridReady prop */
  const onGridReadyPreserveColumnState = useCallback(
    (params: GridReadyEvent) => {
      // console.log("Grid ready, restoring column state");
      restoreState();
      attachEventListeners(params.api);
    },
    [attachEventListeners, restoreState],
  );

  // Function to get column details
  const getColumnDetails = (): ColumnToggleItem[] => {
    if (gridRef.current?.api?.isDestroyed()) return [];
    const columns = gridRef?.current?.api?.getAllGridColumns() || [];

    return columns.map((col) => {
      const colDef = col.getColDef();
      return {
        headerName: colDef.headerName || "",
        colId: colDef.colId || "",
        visible: col.isVisible(),
      };
    });
  };

  // Toggle column visibility
  const setColumnVisibility = (colId: string, isVisible: boolean) => {
    if (gridRef?.current?.api) {
      console.log("Toggling column visibility", colId, isVisible);
      gridRef.current.api.setColumnsVisible([colId], isVisible);
      saveState(); // Save the state after toggling
    }
  };
  // Reset to default state
  const resetColumns = () => {
    if (gridRef?.current?.api) {
      gridRef.current.api.resetColumnState();
      saveState(); // Save the state after resetting
    }
  };

  return (
    <TableColumnStateContext.Provider
      value={{
        // columnState,
        setColumnState,
        // saveState,
        // restoreState,
        reset: resetColumns,
        onGridReadyPreserveColumnState,
        getColumnDetails,
        setColumnVisibility,
        // gridRef,
        setGridRef,
      }}
    >
      {children}
    </TableColumnStateContext.Provider>
  );
};
